import React from "react";
import className from "classnames";
import { Link } from "gatsby";
import classNames from "classnames";

interface PaginationProps {
	maxPages: number;
	pageUrlGen: (ndx: number) => string;
	currentPage: number;
}

const CreateNumber = (iterations: number, Component: any): JSX.Element[] => {
	const components: JSX.Element[] = [];
	for (let i = 1; i <= iterations; i++) {
		components.push((<Component index={iterations} />))
	}
	return components;
}

export const Pagination = (props: PaginationProps) => {
	// if (props.maxPages <= 1) {
	// 	return null;
	// }

	const isFirstPage = props.currentPage === 1;
	const isLastPage = props.maxPages === props.currentPage;

	return (
		<nav>
			<ul className="pagination justify-content-center">
				<li className={classNames("page-item", { "disabled": isFirstPage })}>
					<Link className="page-link" to={props.pageUrlGen(props.currentPage - 1)} tabIndex={isFirstPage ? -1 : undefined}>
						<span aria-hidden="true">&laquo;</span>
						<span className="sr-only">Previous</span>
					</Link>
				</li>
				{CreateNumber(props.maxPages, (fprops: { index: number }) => (
					<li className={className("page-item", { "active": fprops.index === props.currentPage })}>
						<Link className="page-link" to={props.pageUrlGen(fprops.index)} >
							<span>{fprops.index}</span>
							<span className="sr-only">Page {fprops.index}</span>
						</Link>
					</li>
				))}
				<li className={classNames("page-item", { "disabled": isLastPage })}>
					<Link className="page-link" to={props.pageUrlGen(props.currentPage + 1)} tabIndex={isLastPage ? -1 : undefined}>
						<span aria-hidden="true">&raquo;</span>
						<span className="sr-only">Next</span>
					</Link>
				</li>
			</ul>
		</nav>
	)
}